.circle {
  border-radius: 100%;
  height: 85%;
  width: 85%;
}

.circle.red {
  background: radial-gradient(circle at 10px 10px, red, #000);
}

.circle.blue {
  background: radial-gradient(circle at 10px 10px, blue, #000);
}

.circle.selected {
  border: 3px solid #FFD700;
}
