.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-button {
  margin: 1rem;
  width: 80%;
  font-size: 1.5rem;
}

.welcome-message {
  font-size: 1.5rem;
  margin: 1rem;
  max-width: 20rem;
}
