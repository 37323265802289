.copy-container {
  position: relative;
  height: 1rem;
  width: 1rem;
  padding: 0;
  display: inline-block;
  cursor: pointer;
}

.copy-background {
    position: absolute;
    border: 1px solid black;
    height: 80%;
    width: 80%;
    z-index: 1;
}

.copy-foreground {
    position: absolute;
    border: 1px solid black;
    height: 80%;
    width: 80%;
    z-index: 2;
    left: 3px;
    top: 3px;
}
