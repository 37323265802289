.game-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: #bbada0; */
}

.board-row {
  display: flex;
}

.square {
  padding: 0;
  width: 50px;
  height: 50px;
  background: #eee4da59;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
